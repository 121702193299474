import RouterNames from "@/router/route.names";

export default ({
  path: 'my-requests',
  name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequest,
  component: () => import('@/views/Dashboard/AccreditationRequest/AccreditationRequestView.view'),
  children: [
    {
      path: '',
      redirect: { name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequestsRoutes.AccreditationRequestList }
    },
    {
      path: 'list',
      name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequestsRoutes.AccreditationRequestList,
      component: () => import('@/views/Dashboard/AccreditationRequest/AccreditationRequestsList/AccreditationRequestsList.view'),
      props: { breadcrumbs: {text: 'Accreditation Requests', active: true} },
    },
    {
      path: ':request',
      name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequestsRoutes.AccreditationRequestDetails,
      component: () => import('@/views/Dashboard/AccreditationRequest/AccreditationRequestDetails/AccreditationRequestDetails.view'),
      props: { breadcrumbs: { text: 'Accreditation Request Detail', active: true } }
    }
  ]
})
