import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {Fake} from "@/utils/faker.utils";

export class UserModel extends ModelAbstraction {

  id;

  firstName;

  lastName;

  email;

  emailVerifiedAt;

  accountStatus;

  birthDate;

  phone;

  company;

  settings;

  externalUsername;

  emailNotifications;

  created_at;

  updated_at;

  person;

  userOrganizationRoles;
}
