import RouterNames from '@/router/route.names';

export default ({
  path: 'tickets',
  name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequest,
  component: () => import('@/views/Dashboard/TicketRequest/Tickets.view'),
  children: [
    {
      path: '',
      redirect: { name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.TicketRequestList }
    },
    {
      path: 'main-contacts',
      name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.MainContacts,
      component: () => import('@/views/Dashboard/TicketRequest/MainContacts.view'),
      props: { breadcrumbs: { text: 'Main Contacts', active: true } }
    },
    {
      path: 'delivery-address',
      name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.DeliveryAddress,
      component: () => import('@/views/Dashboard/TicketRequest/DeliveryAddress.view'),
      props: { breadcrumbs: { text: 'Delivery Contacts', active: true } }
    },
    {
      path: 'ticket-requests',
      name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.TicketRequestList,
      component: () => import('@/views/Dashboard/TicketRequest/TicketRequestList.view'),
      props: { breadcrumbs: { text: 'Ticket Requests', active: true } }
    },
    {
      path: ':request',
      name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.TicketRequestDetails,
      component: () => import('@/views/Dashboard/TicketRequest/TicketDetails.view'),
      props: { breadcrumbs: { text: 'Request Details', active: true } }
    },
  ]
});
