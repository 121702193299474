import {ApiRequestor} from "@/classes/requestors/api.requestor";

export class DtoApiGetMatchDays extends ApiRequestor {
  async $get(
      seasonId= null,
      hasMatchesForAccreditationRequest,
      hasMatchesForTicketRequest,
      hasMatchesForActivationRequest,
      hasAccreditationRequests,
      hasActivationRequests,
      hasTicketRequests,
      organizationId,
      limit = 100,
      offset = 0
  ) {
    const params = {
      seasonId,
      hasMatchesForAccreditationRequest,
      hasMatchesForTicketRequest,
      hasMatchesForActivationRequest,
      hasAccreditationRequests,
      hasActivationRequests,
      hasTicketRequests,
      organizationId,
      limit
    }
    
    return this.$http('GET', '/matchdays', { params })
  }
}
