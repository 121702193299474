export class CollectionAbstraction {
  models;

  constructor(models = []) {
    this.models = [...models];
  }

  validate() {
    if (!this.models.length) return true;
    return !!this.models
      .map(model => model.validate())
      .filter(status => !!status)
      .length;
  }

  get length() {
    return this.models.length;
  }

  async getFake(model = this.models[0], length = 10) {
    if (!model) return;
    const instance = new this.constructor([]);
    const promises = [...new Array(length)].map(async (item, index) => {
      instance.models[index] = await model.getFake()
    });
    await Promise.all(promises);
    return instance;
  }
}
