import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {IsInt, IsString} from "class-validator";

export class DtoApiGetMatches extends ApiRequestor {
  @IsInt()
  matchId

  @IsInt()
  matchdayId

  @IsString()
  name

  @IsString()
  optionLabel

  @IsString()
  startDateTime

  @IsString()
  status

  @IsString()
  title

  async $get(
      matchdayId = null,
      requestType = null,
      withRequestsFor = null,
      organizationId = null,
      limit = 100,
      matchdayIds = null,
  ) {
    const params = { matchdayId, matchdayIds, requestType, withRequestsFor, organizationId, limit }
    return this.$http('GET', '/matches', { params })
  }
}
