<template>
  <iframe
    :src="src"
    @load="onLoad()"
  />
</template>

<script>
export default {
  name: 'DDMCAF-Authenticator-Logout',
  computed: {
    src() {
      const {apiUrl} = window;
      const url = new URL(apiUrl);
      url.pathname = '/logout';
      return url.toString();
    }
  },
  methods: {
    onLoad() {
      this.$store.state.api.isLogout = false;
    }
  }
}
</script>
