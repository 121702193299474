
import {DtoApiGetCountries} from "@/classes/dto/api/countries/dto.api.get-countries.request";

export default ({
  namespaced: true,
  state: () => ({
    limit: 500,
    page: 1,
    pages: 1,
    orderBy: 'name',
    orderByDirection: 'asc',

    countries: [],
    countriesListMeta: null,
    countryOptions: []
  }),
  mutations: {},
  actions: {
    async getCountries ({state}) {
      const offset = (state.page - 1) * state.limit;
      const orderBy = state.orderBy
      const orderByDirection = state.orderByDirection
      const limit = state.limit

      let params = { orderBy, orderByDirection, offset, limit }

      const res = await new DtoApiGetCountries().$get(params)

      if (res.$error) {
        console.log(res.$error)
      } else {
        state.countries = res.data
        state.countryOptions = res.data.map((item) => {
          return {
            id: item.countryId,
            country: item.name,
            A3: item.isoCodea3
          }
        })
        state.pages = Math.ceil(res.meta.count / state.limit);
        state.countriesListMeta = res.meta;
      }
    },
  },
})
