import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {TeamsCollection} from "@/classes/collections/Teams.collection";
import {TeamModel} from "@/classes/models/Team.model";
import get from 'lodash.get';

export class DtoApiGetTeamList extends ApiRequestor {
    data;

    meta;

    async $get(params) {
        return this.$http('GET', 'teams', {params})
    }

    $toTeamsListCollection() {
        const collection = new TeamsCollection();
        collection.models = this.data.map(item => {
            const model = new TeamModel();

            model.teamId = get(item, 'teamId', '--')
            model.name = get(item, 'name', '--')
            model.abbreviation = get(item, 'abbreviation', '--')
            model.optionTitle = get(item, 'optionTitle', '--')

            return model
        });

        return collection;
    }
}
