import {AccreditationRequestsCollection} from "@/classes/collections/AccreditationRequests.collection";

import {DtoApiGetContactsList} from "@/classes/dto/api/contacts/dto.api.get-contacts-list.request";
import {DtoApiGetContactDetails} from "@/classes/dto/api/contacts/dto.api.get-contact-details.request";
import {DtoApiDeleteContact} from "@/classes/dto/api/contacts/dto.api.contact-delete.request";
import {DtoApiCreateContact} from "@/classes/dto/api/contacts/dto.api.contact-create.request";
import {DtoApiUpdateContact} from "@/classes/dto/api/contacts/dto.api.contact-update.request";

export default ({
  namespaced: true,
  state: () => ({
    limit: 10,
    page: 1,
    pages: 1,
    orderBy: 'created_at',
    orderByDirection: 'desc',
    contactsList: new AccreditationRequestsCollection(),
    contactDetails: null,
    contactsListMeta: null
  }),
  mutations: {},
  actions: {
    async getContacts ({state, rootGetters}, payload) {
      const organizationId = rootGetters['user/organizationId']
      const seasonId = rootGetters['event/selectedSeason'] ? rootGetters['event/selectedSeason'].seasonId : null
      const offset = (state.page - 1) * state.limit;
      const orderBy = state.orderBy
      const type = (payload && payload.type) ? payload.type : 'contact';
      const limit = state.limit
      const orderByDirection = state.orderByDirection
      const params = { organizationId, offset, limit, type, orderBy, orderByDirection, seasonId }
      if(payload && payload.matchdayId) {params.matchdayId = payload.matchdayId}
      if(payload && payload.matchId) {params.matchId = payload.matchId}
      if(payload && payload.organizationId) {params.organizationId = payload.organizationId}
      if(payload && payload.seasonId) {params.seasonId = payload.seasonId}

      const res = await new DtoApiGetContactsList().$get(params)

      if (res.$error) {
        console.log(res.$error)
      } else {
        state.contactsList = res.$toContactsListCollection(res.data)
        state.contactsListMeta = res.meta
        state.pages = Math.ceil(res.meta.count / state.limit);
      }
    },
    async getSingleContact({state, rootGetters}, contactId) {
      const organizationId = rootGetters['user/organizationId']
      const res = await new DtoApiGetContactDetails().$get(organizationId, contactId)

      if (res.$error) {
        console.log(res.$error)
      } else {
        state.contactDetails = res.data
      }
    },
    async deleteContact({state, rootGetters}, {contactId, teamId}) {
      await new DtoApiDeleteContact().$delete(contactId, teamId)
    },
    async createContact({state, rootGetters}, data) {
      const organizationId = rootGetters['user/organizationId']
      const res = await new DtoApiCreateContact().$create(organizationId, data)

      return new Promise((resolve) => {
        resolve(res)
      })
    },
    async updateContact({state, rootGetters}, data) {
      const organizationId = rootGetters['user/organizationId']
      const res = await new DtoApiUpdateContact().$update(organizationId, data)

      return new Promise((resolve) => {
        resolve(res)
      })
    },
  },
})
