import {LOCAL_STORE_ACCOUNT, LOCAL_STORE_SEASON} from '@/local-store.keys';

export default ({
  namespaced: true,
  state: () => ({
    isAuthenticated: true,
    isLogout: false,
  }),
  mutations: {
    login(state) {
      window.localStorage.removeItem(LOCAL_STORE_ACCOUNT);
      window.localStorage.removeItem(LOCAL_STORE_SEASON);
      state.isAuthenticated = false;
      state.isLogout = false;
    },
    logout(state) {
      window.localStorage.removeItem(LOCAL_STORE_ACCOUNT);
      window.localStorage.removeItem(LOCAL_STORE_SEASON);
      state.isAuthenticated = false;
      state.isLogout = true;
      location.reload()
    },
  }
})
