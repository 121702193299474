import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";

export class AccreditationApplicant extends ModelAbstraction {
  id;

  firstName;

  lastName;

  birthDate;

  email;

  phone;

  job;

  nationality;

  nationalityCountryId;

  passportNumber;

  passportPhoto;

  passportName;

  passportPhotoId;

  accreditationPhoto;

  accreditationPhotoId;

  passportExpiryDate;

  gender;

  category = '';

  subCategory = '';

  accreditationType = '';

  activation = '';
}
