import RouterNames from '@/router/route.names';
import DashboardRoutes from "@/router/routes/Dashboard/Dashboard.routes";
import TicketRoutes from '@/router/routes/Dashboard/TicketRequest/TicketRequest.routes';

export default [
  {
    path: '/',
    name: RouterNames.Index,
    component: () => import('@/views/Index.view'),
    children: [
      {path: '', redirect: {name: RouterNames.Home}},
      {path: '/home', name: RouterNames.Home, component: () => import('@/views/Home.view')},
      {path: '/my-profile', name: RouterNames.MyProfile, component: () => import('@/views/MyProfile.view')},
      DashboardRoutes,
      TicketRoutes,
    ]
  },
  {
    path: '*',
    redirect: {name: RouterNames.Home}
  }
];
