import {DtoApiGetUserDetailsRequest} from "@/classes/dto/api/user/dto.api.get-user-details.request";

export default ({
  namespaced: true,
  state: () => ({
    profileDetails: null
  }),
  actions: {
    async getProfileDetails({state}) {
      const res = await new DtoApiGetUserDetailsRequest().$get()

      if (res.$error) {
        console.log(res.$error)
      } else {
        state.profileDetails = res.data
      }
    },
  },
  getters: {
    profileDetails: state => state.profileDetails
  }
})
