import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexRoutes from './routes/Index.routes';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: IndexRoutes
})

export default router
