import {CollectionAbstraction} from "@/classes/abstractions/collection.abstraction";
import {IsInstance, ValidateNested} from "class-validator";
import {ActivationModel} from "@/classes/models/Activation.model";

export class ActivationsCollection extends CollectionAbstraction {
  @IsInstance(ActivationModel)
  @ValidateNested()
  models;

  constructor(models = []) {
    super(models);
    this.models = [...models];
  }

  async getFake(length = 10) {
    const model = new ActivationModel();
    return await super.getFake(model, length);
  }
}
