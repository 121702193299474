import { validateSync } from 'class-validator';
import { plainToClass, classToPlain, deserialize, serialize } from 'class-transformer';
import { getFake } from '@/utils/faker.utils';
import moment from "moment";

export class ModelAbstraction {
  fromJson(json) {
    return plainToClass(this.constructor, json);
  }

  toJson() {
    return classToPlain(this);
  }

  fromString(string) {
    return deserialize(this.constructor, string);
  }

  toString() {
    return serialize(this);
  }

  async getFake() {
    return await getFake(this);
  }

  formatDateValues(keys = []) {
    const data = keys.reduce((accumulator, key) => {
      const value = this[key];
      if (value) accumulator[key] = moment(value).format('YYYY-MM-DD');
      return accumulator;
    }, {})
    return this.fromJson({...this, ...data});
  }

  containsEmptyKeys(keys = Object.keys(this)) {
    return keys.map(key => this[key]).some(value => !value);
  }

  removeEmptyKeys() {
    const data = Object
      .entries(this)
      .reduce((accumulator, [key, value]) => {
        if (value) accumulator[key] = value;
        return accumulator;
      }, {});
    return this.fromJson(data);
  }

  removeKeys(keys = []) {
    const data = this.toJson();
    keys.forEach(key => delete data[key]);
    return this.fromJson(data);
  }

  validate() {
    const errors = validateSync(this);
    const hasError = !!errors.length;
    if (hasError) console.warn(`${errors}`, errors);
    return !hasError;
  }
}
