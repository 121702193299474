import {DtoApiGetSponsorActivation} from "@/classes/dto/api/sponsor/dto.api.get-sponsor-activation.request"

export default ({
  namespaced: true,
  state: () => ({
    activeTab: 'EventInfo',
    activations: null,
    selectedActivations: [],
    eventInfo: {
      match: null,
      season: null,
      matchDay: null,
      eventType: 'match',
    },
  }),
  mutations: {
    selectActivation(state, activation) {
      const subcategories = activation.subcategories.map(item => ({...item, amount: ''}))
      const selectedActivation = {
        ...activation,
        personInfo: {
          contactFirstName: '',
          contactLastName: '',
          contactEmail: '',
          contactPhone: '',
          phoneCountry: ''
        },
        ...{subcategories}
      }
      const selected = state.selectedActivations.find(({ activityId }) => activityId === activation.activityId)
      if (!selected) {
        state.selectedActivations = [...state.selectedActivations, selectedActivation]
      } else {
        state.selectedActivations = state.selectedActivations.filter(({activityId}) => activityId !== selected.activityId)
      }
    },
    setSponsorEventInfo(state, payload) {
      state.eventInfo = payload
    },
    changeActiveTab(state, payload) {
      state.activeTab = payload
    },
    clearState(state) {
      state.activeTab = 'EventInfo'
      state.eventInfo = { match: null, season: null, matchDay: null, eventType: 'match' }
      state.selectedActivations = []
    }
  },
  actions: {
    async getActivations ({ state, rootGetters }) {
      const season = rootGetters['event/selectedSeason']
      const organizationId = rootGetters['user/organizationId']
      const res = await new DtoApiGetSponsorActivation().$get(season.competitionId, organizationId)
      state.activations = res.$toActivationsCollection().models
    }
  }
})
