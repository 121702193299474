import {ApiRequestor} from "@/classes/requestors/api.requestor";

export class DtoApiGetUserDetailsRequest extends ApiRequestor {
    data;

    status;

    async $get(params) {

        return this.$http('GET', `/users/profile`, {params})
    }
}
