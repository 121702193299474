import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {AccreditationRequestsCollection} from "@/classes/collections/AccreditationRequests.collection";
import {AccreditationRequestModel} from "@/classes/models/AccreditationRequest.model";
import get from 'lodash.get';

export class DtoApiGetAccreditations extends ApiRequestor {
  data;

  meta;

  async $get(params) {
    return this.$http('GET', '/accreditationRequests', {params})
  }

  $toAccreditationsListCollection() {
    const collection = new AccreditationRequestsCollection();
    collection.models = this.data.map(item => {
      const model = new AccreditationRequestModel();

      model.id = get(item, 'accreditationId', '--')
      model.requestDate = get(item, 'created_at', '--')
      model.matchday = get(item, 'event.matchday.name', '--')
      model.match = get(item, 'event.name', '--')
      model.event = get(item, 'event.name', '--')
      model.category = get(item, 'subcategory.category.name', '--')
      model.subCategory = get(item, 'subcategory.name', '--')
      model.accredType = get(item, 'accreditation_type.name', '--')
      model.firstName = get(item, 'person.firstName', '--')
      model.lastName = get(item, 'person.lastName', '--')
      model.status = get(item, 'status', '--')
      model.person = get(item, 'person', '--')
      model.organization = get(item, 'organization', '--')
      model.allVenues = get(item, 'allVenues', 0)
      model.venues = get(item, 'venues', [])

      return model
    });
    return collection;
  }
}
