import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {ActivationsCollection} from "@/classes/collections/Activations.collection";
import { ContactModel } from "@/classes/models/Contact.model";
import get from "lodash.get";

export class DtoApiGetContactsList extends ApiRequestor {
  data;

  meta;

  async $get(params) {
    return this.$http('GET', `ticketContacts`, {params})
  }

  $toContactsListCollection(arr) {
    const collection = new ActivationsCollection();
    const data = arr || this.data
    collection.models = data.map(item => {
      const model = new ContactModel();

      model.contactId = get(item, 'ticketContactId', '--')
      model.firstname = get(item, 'firstname', '--')
      model.lastname = get(item, 'lastname', '--')
      model.address = get(item, 'contact.street', '--')
      model.city = get(item, 'contact.city', '--')
      model.zip = get(item, 'contact.zip', '--')
      model.country = get(item, 'contact.country', '--')
      model.email = get(item, 'contact.email', '--')
      model.phone = get(item, 'contact.cellPhone') || get(item, 'contact.homePhone') || '--'
      model.team = get(item, 'team', {})
      model.teams = get(item, 'teams', [])

      if(model.country instanceof Object){
        model.country.id = get(model.country, 'id', get(model.country, 'countryId', 0))
        model.country.A3 = get(model.country, 'A3', get(model.country, 'iocCode', '--'))
        model.country.country = get(model.country, 'country', get(model.country, 'title', '--'))
      }

      return model;
    });
    return collection;
  }
}
