import {DtoApiGetActivationList} from "@/classes/dto/api/sponsor/dto.api.get-activation-list.request";
import {DtoApiGetActivationDetails} from "@/classes/dto/api/sponsor/dto.api.get-activation-details.request";
import {ActivationsCollection} from "@/classes/collections/Activations.collection";

export default ({
  namespaced: true,
  state: () => ({
    limit: 10,
    page: 1,
    pages: 1,
    orderBy: 'created_at',
    orderByDirection: 'desc',

    activationsList: new ActivationsCollection(),
    activationsListMeta: null,
    activationsArray: [],
    activationDetails: null
  }),
  mutations: {},
  actions: {
    async getActivations ({state, rootGetters}, payload) {
      const organizationId = rootGetters['user/organizationId']
      const seasonId = rootGetters['event/selectedSeason'] ? rootGetters['event/selectedSeason'].seasonId : ''
      const offset = (state.page - 1) * state.limit;
      const orderBy = state.orderBy;
      const orderByDirection = state.orderByDirection;
      const limit = payload.limit ?? state.limit;
      const params = { organizationId, seasonId, orderBy, orderByDirection, offset, limit }
      if(payload && payload.matchdayId) {params.matchdayId = payload.matchdayId}
      if(payload && payload.matchId) {params.matchId = payload.matchId}

      const res = await new DtoApiGetActivationList().$get(params)

      if (res.$error) {
        console.log(res.$error)
      } else {
        state.activationsArray = res.data
        state.activationsListMeta = res.meta
        state.activationsList = res.$toActivationsListCollection(res.data)
        state.pages = Math.ceil(res.meta.count / state.limit);
      }
    },
    async getSingleActivation({state, rootGetters}, activationId) {
      const organizationId = rootGetters['user/organizationId']
      const res = await new DtoApiGetActivationDetails().$get(organizationId, activationId)

      if (res.$error) {
        console.log(res.$error)
      } else {
        state.activationDetails = res.data
      }
    },
  },
})
