import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {Fake} from "@/utils/faker.utils";
import {IsInt, IsString} from "class-validator";

export class UserAccountModel extends ModelAbstraction {
  @Fake('company.companyName')
  @IsString()
  name;

  @Fake('random.number')
  @IsInt()
  id;

  categories;

  permissions;

  shortSelectName;

  constructor(name, id, categories, competitions, permissions) {
    super();
    this.name = name;
    this.shortSelectName = name.length > 25 ? name.slice(0, 25) + '...' : name;
    this.id = id;
    this.categories = categories;
    this.competitions = competitions;
    this.permissions = permissions;
  }

  getCategoryById() {
    return this.categories;
  }
}
