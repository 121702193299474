import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";

export class AccreditationRequestModel extends ModelAbstraction {
  requestDate

  matchday

  match

  event

  category

  subCategory

  accredType

  firstName

  lastName

  status

  person

  organization

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }
}
