import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {ActivationsCollection} from "@/classes/collections/Activations.collection";
import {ActivationModel} from "@/classes/models/Activation.model";

export class DtoApiGetSponsorActivation extends ApiRequestor {
  data;

  meta;

  async $get(competitionId, organizationId) {
    const params = {organizationId}
    return this.$http('GET', `competitions/${competitionId}/activities`, {params})
  }

  $toActivationsCollection(arr) {
    const collection = new ActivationsCollection();
    const data = arr || this.data

    if (data) {
      collection.models = data.map(item => {
        const model = new ActivationModel();
        ['activityId', 'competitionActivityId', 'name', 'title', 'subcategories']
            .forEach(key => model[key] = item[key]);

        return model;
      });
    }

    return collection;
  }
}
