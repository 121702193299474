import {TeamsCollection} from "@/classes/collections/Teams.collection";

import {DtoApiGetTeamList} from "@/classes/dto/api/event/dto.api.get-teams.request";

export default ({
    namespaced: true,
    state: () => ({
        limit: 200,
        page: 1,
        pages: 1,
        orderBy: 'localTitle',
        orderByDirection: 'asc',

        teamsArray: [],
        teamsList: new TeamsCollection(),
        teamsListMeta: null,
    }),
    mutations: {},
    actions: {
        async getTeams ({state, rootGetters}, payload) {
            const offset = (state.page - 1) * state.limit;
            const orderBy = state.orderBy
            const orderByDirection = state.orderByDirection
            const limit = state.limit

            let params = { orderBy, orderByDirection, offset, limit }

            if (!!payload) {
                if (payload.limit) {
                    params.limit = payload.limit
                }
                if (payload.name) {
                    params.name = payload.name
                }
                if (payload.seasonId) {
                    params.seasonId = payload.seasonId
                }
                if (payload.abbreviation) {
                    params.abbreviation = payload.abbreviation
                    params.sortBy = 'abbreviation'
                }
            }

            const season = rootGetters['event/selectedSeason']
            if (season && !params.seasonId) {
                params.seasonId = season.seasonId
            }

            const res = await new DtoApiGetTeamList().$get(params, payload)

            if (res.$error) {
                console.log(res.$error)
            } else {
                state.teamsArray = res.data
                state.teamsList = res.$toTeamsListCollection(res.data)
                state.pages = Math.ceil(res.meta.count / state.limit);
                state.teamsListMeta = res.meta;
            }
        },
    },
})
