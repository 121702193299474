import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const context = require.context('./modules', true, /(.module.js)$/);
const modules = context
  .keys()
  .reduce((accumulator, module) => {
    const key = module
      .replace('./', '')
      .replace('.module.js', '');
    accumulator[key] = context(module).default;
    return accumulator;
  }, {});
export default new Vuex.Store({
  modules,
})
