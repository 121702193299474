import {DtoApiGetMatchDays} from "@/classes/dto/api/event/dto.api.get-match-days.request";
import {DtoApiGetMatches} from "@/classes/dto/api/event/dto.api.get-matches.request";
import {DtoApiGetSeasons} from "@/classes/dto/api/event/dto.api.get-seasons.request";
import {DtoApiGetTeamList} from "@/classes/dto/api/event/dto.api.get-teams.request";

export default ({
    namespaced: true,
    state: () => ({
        matchDaysList: [],
        matchesList: [],
        seasonsList: [],
        competitionSeasonsList: [],
        teamsList: [],
        selectedSeason: []
    }),
    mutations: {},
    actions: {
        async getMatchDays({state, rootGetters}, payload = {}) {
            const organizationId = rootGetters['user/organizationId']

            let {
                hasMatchesForAccreditationRequest,
                hasMatchesForTicketRequest,
                hasMatchesForActivationRequest,
                hasAccreditationRequests,
                hasActivationRequests,
                hasTicketRequests
            } = payload;

            // handle page refresh on create requests pages
            if (window.location.href.indexOf('dashboard/request-accreditation') > -1) {
                hasMatchesForAccreditationRequest = 1
            }
            if (window.location.href.indexOf('dashboard/my-requests/list') > -1) {
                hasAccreditationRequests = 1
            }
            if (window.location.href.indexOf('dashboard/my-activations/list') > -1) {
                hasActivationRequests = 1
            }
            if (window.location.href.indexOf('dashboard/tickets/ticket-requests') > -1) {
                hasTicketRequests = 1
            }
            if (window.location.href.indexOf('dashboard/request-stadium-tickets') > -1) {
                hasMatchesForTicketRequest = 1
            }
            if (window.location.href.indexOf('dashboard/announce-your-sponsor-activities') > -1) {
                hasMatchesForActivationRequest = 1
            }

            if(state.selectedSeason) {
                const seasonId = state.selectedSeason.seasonId
                const res = await new DtoApiGetMatchDays().$get(
                    seasonId,
                    hasMatchesForAccreditationRequest,
                    hasMatchesForTicketRequest,
                    hasMatchesForActivationRequest,
                    hasAccreditationRequests,
                    hasActivationRequests,
                    hasTicketRequests,
                    organizationId
                )
                if (res.$error) {
                    console.log(res.$error)
                } else {
                    if (hasMatchesForAccreditationRequest || hasMatchesForTicketRequest || hasMatchesForActivationRequest) {
                        state.matchDaysList = res.data.map(matchday => {
                            if (hasMatchesForAccreditationRequest) {
                                matchday.$isDisabled = !matchday.hasMatchesForAccreditationRequest
                            } else if (hasMatchesForTicketRequest) {
                                matchday.$isDisabled = !matchday.hasMatchesForTicketRequest
                            } else if (hasMatchesForActivationRequest) {
                                matchday.$isDisabled = !matchday.hasMatchesForActivationRequest
                            }

                            return matchday
                        })
                    } else {
                        state.matchDaysList = res.data
                    }
                }
            }
        },
        async getMatches({state, rootGetters}, payload) {
            const organizationId = rootGetters['user/organizationId']

            const {matchdayId, matchdayIds, requestType, withRequestsFor, limit} = payload;

            const res = await new DtoApiGetMatches().$get(
                matchdayId,
                requestType,
                withRequestsFor,
                organizationId,
                limit,
                matchdayIds,
            );
            if (res.$error) {
                console.log(res.$error)
            } else {
                if (requestType) {
                    state.matchesList = res.data.map(match => {
                        match.$isDisabled = !match.isAccessible

                        return match
                    })
                } else {
                    state.matchesList = res.data
                }
            }
        },
        async getTeams({state}, payload) {
            const res = await new DtoApiGetTeamList().$get(payload)
            if (res.$error) {
                console.log(res.$error)
            } else {
                state.teamsList = res.data
            }
        },
        async getSeasons({state, rootGetters}) {
            const organizationId = rootGetters['user/organizationId']
            const res = await new DtoApiGetSeasons().$get(organizationId)
            if (res.$error) {
                console.log(res.$error)
            } else {
                if (Array.isArray(res.data)) {
                    state.seasonsList = res.data
                } else {
                    state.seasonsList = Object.values(res.data)
                }
            }
        },
        async getCompetitionSeasons({state, rootGetters}) {
            const organizationId = rootGetters['user/organizationId']
            const competitionId = state.selectedSeason.competitionId
            const res = await new DtoApiGetSeasons().$get(organizationId, competitionId)
            if (res.$error) {
                console.log(res.$error)
            } else {
                if (Array.isArray(res.data)) {
                    state.competitionSeasonsList = res.data
                } else {
                    state.competitionSeasonsList = Object.values(res.data)
                }
            }
        },
    },
    getters: {
        selectedSeason: state => state.selectedSeason,
    }
})
