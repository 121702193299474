import {ApiRequestor} from "@/classes/requestors/api.requestor";
import get from "lodash.get";

export class DtoApiUpdateContact extends ApiRequestor {
  async $update(organizationId, data) {
    const contactId = data.contactId
    const payload = {
      organizationId,
      type: get(data, 'contactType'),
      teamIds: get(data, 'teams').map(function (value){
        return value.teamId;
      }),
      firstname: get(data, 'firstname'),
      lastname: get(data, 'lastname'),
      email: get(data, 'email'),
      phone: get(data, 'phone'),
      phoneCountry: get(data, 'phoneCountry.dialCode'),

      seasonId: get(data, 'season.seasonId', null),
      countryId: get(data, 'country.id', null),
      address: get(data, 'address', null),
      zip: get(data, 'zip', null),
      city: get(data, 'city', null),
    }

    return this.$http('POST', `ticketContacts/${contactId}`, {payload});
  }
}
