<template>
  <section class="App">
    <Authenticator/>
    <router-view v-if="this.$store.state.api.isAuthenticated"/>
  </section>
</template>

<script>
import Authenticator from '@/components/Authenticator/Authenticator.component';

export default {
  components: {Authenticator}
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins/fill.mixin";

.App {
  @include fill();
}
</style>
