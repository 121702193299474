<template>
  <iframe id="loginWindow"
    ref="iframe"
    :src="src"
    @load="onLoad()"
  />
</template>

<script>
export default {
  name: 'DDMCAF-Authenticator-Login',
  computed: {
    src() {
      const {apiUrl, clientId} = window;
      const url = new URL(apiUrl);
      url.pathname += '/login';
      url.searchParams.append('clientId', clientId);
      url.searchParams.append('back', 'cb.html');
      return url.toString();
    }
  },
  created() {
  },
  methods: {
    async onLoad() {
      const {iframe} = this.$refs;
      const content = iframe.contentWindow;

      try {
        const isRedirected = content.location.pathname.includes('cb.html');
        if (isRedirected) {
          await this.$store.dispatch('user/loadAuthInfo');
          this.$store.state.api.isAuthenticated = true;
          this.$store.state.api.isLogout = false;
        }
      } catch(e) {}
    },
  }
}
</script>
