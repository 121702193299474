import {ApiRequestor} from "@/classes/requestors/api.requestor";

export class DtoApiGetSeasons extends ApiRequestor {
  async $get(organizationId = null,
             competitionId = null,
             limit = 100,
             offset = 0
  ) {
    const params = { organizationId, competitionId, limit, offset}
    return this.$http('GET', 'events/seasons', { params })
  }
}
