import RouterNames from '@/router/route.names';
import AccreditationRequestRoutes from './AccreditationRequests/AccreditationRequest.routes';
import SponsorActivationRoutes from './SponsorActivationRequest/SponsorActivationRequest.routes';
import TicketRequestRoutes from './TicketRequest/TicketRequest.routes';
import PersonRoutes from './Person/Person.routes';

export default ({
  path: 'dashboard',
  component: () => import('@/views/Dashboard/Dashboard.view'),
  props: {
    breadcrumbs: { text: 'HOME', active: true, redirectToNamedRoute: RouterNames.Home }
  },
  children: [
    {
      path: '',
      redirect: { name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequest },
    },
    {
      path: 'request-accreditation',
      name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequest,
      component: () => import('@/views/Dashboard/AccreditationRequest/RequestAccreditation/RequestAccreditation.view'),
      props: {
        breadcrumbs: { text: 'Request Accreditation', active: true }
      }
    },
    {
      path: 'request-stadium-tickets',
      name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequest,
      component: () => import('@/views/Dashboard/TicketRequest/TicketRequest.view'),
      props: {
        breadcrumbs: { text: 'Request Match Tickets', active: true }
      }
    },
    {
      path: 'announce-your-sponsor-activities',
      name: RouterNames.IndexRoutes.DashboardRoutes.SponsorActivation,
      component: () => import('@/views/Dashboard/SponsorActivation/SponsorRequest/SponsorActivation.view'),
      props: {
        breadcrumbs: { text: 'Activation Request', active: true }
      }
    },
    {
      path: 'teams',
      name: RouterNames.IndexRoutes.DashboardRoutes.Teams,
      component: () => import('@/views/Dashboard/TicketRequest/Teams.view'),
      props: {
        breadcrumbs: { text: 'List of teams', active: true }
      }
    },
    TicketRequestRoutes,
    AccreditationRequestRoutes,
    SponsorActivationRoutes,
    PersonRoutes,
  ]
});
