export default {
  Home: 'Home',
  MyProfile: 'MyProfile',

  Index: 'Index',
  IndexRoutes: {
    Dashboard: 'Dashboard',
    DashboardRoutes: {
      MyProfile: 'MyProfile',

      Person: 'Person',
      PersonRoutes: {
        PersonList: 'PersonList',
        PersonCreate: 'PersonCreate',
        PersonCreateBulk: 'PersonCreateBulk',
        PersonEdit: 'PersonEdit'
      },

      AccreditationRequest: 'AccreditationRequests',
      AccreditationRequestsRoutes: {
        AccreditationRequestList: 'AccreditationRequestsList',
        AccreditationRequestDetails: 'AccreditationRequestDetails'
      },

      SponsorActivation: 'SponsorActivation',
      SponsorActivationRoutes: {
        SponsorActivationList: 'SponsorActivationsList',
        SponsorActivationDetails: 'SponsorActivationDetails'
      },

      TicketRequest: 'Tickets',
      TicketRequestRoutes: {
        DeliveryAddress: 'DeliveryAddress',
        MainContacts: 'MainContacts',
        TicketRequestList: 'TicketRequestList',
        TicketRequestDetails: 'TicketRequestDetails',
      },

      Teams: 'Teams',
    }
  }
}
