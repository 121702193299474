import {ApiRequestor} from "@/classes/requestors/api.requestor";

export class DtoApiGetActivationDetails extends ApiRequestor {
  data;

  status;

  async $get(organizationId, activationId) {
    const params = { organizationId }
    return this.$http('GET', `/bookedActivity/${activationId}`, {params})
  }
}