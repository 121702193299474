import {AccreditationRequestsCollection} from "@/classes/collections/AccreditationRequests.collection";

import {DtoApiGetAccreditations} from "@/classes/dto/api/accreditations/dto.api.get-accreditations.request";
import {DtoApiGetSingleAccreditation} from "@/classes/dto/api/accreditations/dto.api.get-single-accreditations.request";

export default ({
  namespaced: true,
  state: () => ({
    limit: 10,
    page: 1,
    pages: 1,
    customOrderBy: '',
    orderBy: 'created_at',
    orderByDirection: 'asc',
    accreditationListMetaData: null,
    accreditationRequestsList: new AccreditationRequestsCollection(),
    singleRequestDetails: null,
  }),
  mutations: {},
  actions: {
    async getAccreditations ({state, rootGetters}, payload) {
      const organizationId = rootGetters['user/organizationId'];
      const seasonId = rootGetters['event/selectedSeason'] ? rootGetters['event/selectedSeason'].seasonId : '';
      const offset = (state.page - 1) * state.limit;
      const orderBy = state.orderBy;
      const customOrderBy = state.customOrderBy;
      const limit = state.limit;
      const orderByDirection = state.orderByDirection;
      const searchText = payload?.searchText ?? '';
      const params = { organizationId, seasonId, orderBy, orderByDirection,customOrderBy, offset, limit, searchText };
      if(payload && payload.matchdayId) {params.matchdayId = payload.matchdayId};
      if(payload && payload.matchId) {params.matchId = payload.matchId};
      if(payload && payload.organizationId) {params.organizationId = payload.organizationId};

      const response = await new DtoApiGetAccreditations().$get(params);

      if (response.$error) {
        console.log(response.$error);
      } else {
        state.accreditationRequestsList = response.$toAccreditationsListCollection(response.data);
        state.accreditationListMetaData = response.meta;
        state.pages = Math.ceil(response.meta.count / state.limit);
      }
    },
    async getSingleAccreditation({state, rootGetters}, accreditationId) {
      const organizationId = rootGetters['user/organizationId'];
      const res = await new DtoApiGetSingleAccreditation().$get(organizationId, accreditationId);

      if (res.$error) {
        console.log(res.$error);
      } else {
        state.singleRequestDetails = res.data;
      }
    },
  },
})
