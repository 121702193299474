import {AccreditationRequestsCollection} from "@/classes/collections/AccreditationRequests.collection";

import {DtoApiGetTicketList} from "@/classes/dto/api/ticket/dto.api.get-ticket-list.request";
import {DtoApiGetTicketDetails} from "@/classes/dto/api/ticket/dto.api.get-ticket-details.request";

export default ({
  namespaced: true,
  state: () => ({
    limit: 10,
    page: 1,
    pages: 1,
    orderBy: 'created_at',
    orderByDirection: 'desc',

    ticketRequestList: new AccreditationRequestsCollection(),
    ticketRequestDetails: null,
    ticketRequestListMeta: null
  }),
  mutations: {},
  actions: {
    async getTicketRequests ({state, rootGetters}, payload) {
      const organizationId = rootGetters['user/organizationId']
      const seasonId = rootGetters['event/selectedSeason'] ? rootGetters['event/selectedSeason'].seasonId : ''
      const offset = (state.page - 1) * state.limit;
      const orderBy = state.orderBy
      const orderByDirection = state.orderByDirection
      const limit = state.limit
      const params = { organizationId, seasonId, orderBy, orderByDirection, offset, limit }
      if(payload && payload.matchdayId) {params.matchdayId = payload.matchdayId}
      if(payload && payload.matchId) {params.matchId = payload.matchId}
      if(payload && payload.organizationId) {params.organizationId = payload.organizationId}
      if(payload && payload.searchText){params.searchText = payload.searchText}

      const res = await new DtoApiGetTicketList().$get(params)

      if (res.$error) {
        console.log(res.$error)
      } else {
        state.ticketRequestList = res.$toTicketsListCollection(res.data)
        state.pages = Math.ceil(res.meta.count / state.limit);
        state.ticketRequestListMeta = res.meta;
      }
    },
    async getSingleTicketRequest({state, rootGetters}, ticketRequestId) {
      const organizationId = rootGetters['user/organizationId']
      const res = await new DtoApiGetTicketDetails().$get(organizationId, ticketRequestId)

      if (res.$error) {
        console.log(res.$error)
      } else {
        state.ticketRequestDetails = res.data
      }
    },
  },
})
