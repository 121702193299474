import RouterNames from "@/router/route.names";

export default ({
  path: 'my-team',
  name: RouterNames.IndexRoutes.DashboardRoutes.Person,
  component: () => import('@/views/Dashboard/Person/Person.view'),
  redirect: {name: RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonList},
  props: {
    breadcrumbs: {text: 'Person Management', active: true}
  },
  children: [
    {
      path: 'list',
      name: RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonList,
      component: () => import('@/views/Dashboard/Person/PersonList/PersonList.view')
    },
    {
      path: 'create',
      name: RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonCreate,
      component: () => import('@/views/Dashboard/Person/PersonCreate/PersonCreate.view'),
      props: {
        breadcrumbs: {text: 'Create Person', active: true}
      },
    },
    {
      path: 'import',
      name: RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonCreateBulk,
      component: () => import('@/views/Dashboard/Person/PersonCreateBulk/PersonCreateBulk.view'),
      props: {
        breadcrumbs: {text: 'Bulk Import Persons', active: true}
      },
    },
    {
      path: 'edit/:personId',
      name: RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonEdit,
      component: () => import('@/views/Dashboard/Person/PersonEdit/PersonEdit.view'),
      props: {
        breadcrumbs: {text: 'Edit Existing Person', active: true}
      },
    },
  ]
})
