import {ApiRequestor} from "@/classes/requestors/api.requestor";

export class DtoApiGetSingleAccreditation extends ApiRequestor {
  data;

  status;

  async $get(organizationId, accreditationId) {
    const params = { organizationId }
    return this.$http('GET', `/accreditationRequests/${accreditationId}`, {params})
  }
}
