import store from '@/store'
import axios from 'axios';
import {DtoAbstraction} from "@/classes/abstractions/dto.abstraction";

//toast notification
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import get from "lodash.get";
import flatten from "lodash.flatten";
Vue.use(VueToast);

export class ApiRequestor extends DtoAbstraction {
  constructor() {
    super(axios, {
      baseURL: window.apiUrl,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      withCredentials: true,
    });
  }

  async $http(method, url, {payload, params} = {}) {
    const response = await super.$http(method, url, {payload, params});

    if (response.$status === 401) store.commit('api/login');

    if (response.$status === 500) {
      Vue.$toast.open({
        message: 'Something went wrong.<br .> <small>Please contact administrator.</small>',
        type: 'error',
        dismissible: true
      });
    }

    // validation error
    if (response.$status === 422) {
      const errorsReceived = get(response, '$response.data.data.errors', {});
      const errors = Object.values(errorsReceived).length
          ? flatten(Object.values(errorsReceived))
          : [];
      const message = get(response, '$response.data.message');
      const messages = flatten(errors);
      const fullErrorMessage = messages.length ? messages.join('<br/>') : message;

      Vue.$toast.open({
        message: fullErrorMessage,
        type: 'error',
        dismissible: true
      });
    }

    // user doesn't have proper permissions
    if (response.$status === 403) {
      Vue.$toast.open({
        message: response.$response.data.message,
        type: 'error',
        dismissible: true
      });
    }

    return response;
  }

  get isFailure() {
    return this.status === 'failure';
  }

  get isSuccessful() {
    return this.status === 'success';
  }

  get hasError() {
    return this.status === 'error';
  }

  get isDuplicate() {
    return this.data.duplicates !== 'undefined';
  }
}
