import {ApiRequestor} from "@/classes/requestors/api.requestor";

export class DtoApiGetContactDetails extends ApiRequestor {
  data;

  status;

  async $get(organizationId, contactId) {
    const params = { organizationId }
    return this.$http('GET', `/ticketContacts/${contactId}`, {params})
  }
}