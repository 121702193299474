import get from 'lodash.get';
import {UserModel} from "@/classes/models/User.model";
import {UserAccountsCollection} from "@/classes/collections/UserAccounts.collection";
import {DtoApiAuthInfoRequest} from "@/classes/dto/api/dto.api.auth-info.request";

export default ({
  namespaced: true,
  state: () => ({
    model: new UserModel(),
    accounts: new UserAccountsCollection(),
    account: null,
    league: null
  }),
  actions: {
    async getUser({state}) {
      state.model = await new UserModel().getFake();
    },
    async getAccounts({state}) {
      state.accounts = await new UserAccountsCollection().getFake();
    },
    async loadAuthInfo({state}) {
      const request = await new DtoApiAuthInfoRequest().$get();
      state.model = request.$toUserModel();
      state.accounts = request.$toUserAccountsCollection();
    }
  },
  getters: {
    organizationId: state => state.account.id,
    league: state => state.league,
    permissions: state => get(state, 'account.permissions', []),
    haveAccessToAccreditation: state => {
      const permissions = get(state, 'account.permissions', []);
      return [
        permissions.includes('accreditationRequests:viewAPI'),
        permissions.includes('accreditationRequests:createAPI'),
        permissions.includes('persons:viewAPI'),
        permissions.includes('persons:createAPI'),
        permissions.includes('persons:updateAPI'),
        permissions.includes('persons:deleteAPI'),
        permissions.includes('persons:importAPI'),
        permissions.includes('accreditationForms:viewAPI'),
      ].every(status => status === true);
    },
    haveAccessToActivations: state => {
      const permissions = get(state, 'account.permissions', []);
      return [
        permissions.includes('bookedActivities:viewAPI'),
        permissions.includes('bookedActivities:createAPI'),
        permissions.includes('activities:viewAPI'),
      ].every(status => status === true);
    },
    haveAccessToTickets: state => {
      const permissions = get(state, 'account.permissions', []);

      return [
        permissions.includes('ticketRequests:viewAPI'),
        permissions.includes('ticketRequests:createAPI'),
        permissions.includes('ticketContacts:viewAPI'),
        permissions.includes('ticketContacts:createAPI'),
        permissions.includes('ticketContacts:updateAPI'),
        permissions.includes('ticketContacts:deleteAPI'),
        permissions.includes('ticketContacts:importAPI'),
        permissions.includes('tickets:viewAPI'),
      ].every(status => status === true);
    },
    haveAccessToBroadcasterBooking: state => {
      const permissions = get(state, 'account.permissions', []);
      return [
        permissions.includes('broadcasterBooking:viewAPI'),
      ].every(status => status === true);
    },
    haveAccessToInformationCenter: state => {
      const permissions = get(state, 'account.permissions', []);

      return [
        permissions.includes('informationCenter:viewAPI'),
      ].every(status => status === true);
    },
    haveAccessToApprovals: state => {
      const permissions = get(state, 'account.permissions', []);
      return [
        permissions.includes('approvals:viewAPI'),
      ].every(status => status === true);
    },
    haveAccessToBrandAccess: state => {
      const permissions = get(state, 'account.permissions', []);
      return [
        permissions.includes('brandAccess:viewAPI'),
      ].every(status => status === true);
    },
    getBroadcasterBookingUrl: () => {
      return window.broadcastBookingUrl[window.environment]
    },
    hasPARCategory: state => {
      let hasParCategory = false
      get(state, 'account.categories', []).forEach((category) => {
        if (category.seedKey === 'PAR') {
          hasParCategory = true
        }
      })

      return hasParCategory
    }
  }
})
