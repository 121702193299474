import axios from 'axios';
import { Exclude } from 'class-transformer';
import { ModelAbstraction } from './model.abstraction';

export class DtoAbstraction extends ModelAbstraction {
  @Exclude()
  $axios;

  @Exclude()
  $httpOptions;

  @Exclude()
  $loading = false;

  @Exclude()
  $status;

  @Exclude()
  $error;

  @Exclude()
  $response;

  async $http(method, url, {payload, params} = {}) {
    this.showLoader("block");

    let instance = new this.constructor();
    let response;
    const headers = {};
    if (payload instanceof FormData) headers['Content-Type'] = 'multipart/form-data';

    if (document.cookie.indexOf('XDEBUG_SESSION') > -1) {
        url += (url.indexOf('?') > -1 ? '&' : '?') + 'XDEBUG_SESSION_START=phpstorm';
    }

    this.$loading = true;
    try {
      response = await this.$axios({ method, url, params, data: payload, ...this.$httpOptions });
      instance = instance.fromJson(response.data);
    } catch (error) {
      response = error.response;
      instance.$error = error;
    }
    instance.$response = response;
    instance.$status = response.status;
    instance.$axios = this.$axios;
    instance.$httpOptions = this.$httpOptions;
    this.$loading = false;
    this.showLoader("none");

    return instance;
  }

  constructor($axios = axios, $httpOptions = {}) {
    super();
    this.$axios = $axios;
    this.$httpOptions = $httpOptions;
  }

  showLoader(display) {
    let loader = document.getElementById("loader");
    if (loader) {
      loader.style.display = display;
    }
  }
}
