import {CollectionAbstraction} from "@/classes/abstractions/collection.abstraction";
import {IsInstance, ValidateNested} from "class-validator";
import {UserAccountModel} from "@/classes/models/UserAccount.model";

export class UserAccountsCollection extends CollectionAbstraction {
  @IsInstance(UserAccountModel)
  @ValidateNested()
  models;

  constructor(models = []) {
    super(models);
    this.models = [...models];
  }

  async getFake(length = 10) {
    const model = new UserAccountModel();
    return await super.getFake(model, length);
  }
}
