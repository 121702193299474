import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {IsNumber, IsString} from "class-validator";

export class ActivationModel extends ModelAbstraction {
  @IsNumber()
  activityId;

  @IsNumber()
  competitionActivityId;

  @IsString()
  name;

  @IsString()
  title;

  subcategories;

  concept;

  concepts;

  match;

  type;

  status;

}
