import Catch from 'catch-decorator';
import noop from 'lodash.noop';
import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {IsEmail, IsInt, IsString} from "class-validator";
import {UserModel} from '@/classes/models/User.model';
import {UserAccountModel} from '@/classes/models/UserAccount.model';
import {UserAccountsCollection} from '@/classes/collections/UserAccounts.collection';
import {Transform} from "class-transformer";

export class DtoApiAuthInfoRequest extends ApiRequestor {
  @IsInt()
  userId;

  @IsEmail()
  email;

  @IsString()
  firstName;

  @IsString()
  lastName;

  organizations;

  @Transform(value => Object.values(value))
  categories;

  @Transform(value => Object.values(value))
  competitions;

  async $get() {
    return await this.$http('get', '/auth');
  }

  @Catch(Error, noop)
  $toUserModel() {
    const model = new UserModel();
    model.name = `${this.firstName} ${this.lastName}`;
    model.email = this.email;
    return model;
  }

  @Catch(Error, noop)
  $toUserAccountsCollection() {
    const organizations = Object.values(this.organizations);
    const models = organizations.map(({name, organizationId, categories, permissions}) => {
      const parsedCategories = categories;
      const parsedCompetitions = this.competitions;
      const parsedPermissions = permissions.permissions;
      return new UserAccountModel(name, organizationId, parsedCategories, parsedCompetitions, parsedPermissions)
    });
    return new UserAccountsCollection(models);
  }
}
