import {ApiRequestor} from "@/classes/requestors/api.requestor";

export class DtoApiGetCountries extends ApiRequestor {
    data;

    meta;

    async $get(params) {
        return this.$http('GET', 'countries', {params})
    }
}
