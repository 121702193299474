import {ApplicantsCollection} from "@/classes/collections/Applicants.collection";
import {DtoApiPostAccreditations} from "@/classes/dto/api/accreditations/dto.api.post-accreditations.request";
import {DtoApiAccreditationMatrix} from "@/classes/dto/api/accreditations/dto.api.get-matrix.request";
import {DtoApiEventPerson} from "@/classes/dto/api/accreditations/dto.api.get-event-person.request";

export default ({
    namespaced: true,
    state: () => ({
        activeTab: 'EventInformation',
        usersList: new ApplicantsCollection(),
        selectedUsers: [],
        eventInfo: {
            match: null,
            matches: [],
            season: null,
            matchDay: null,
            matchDays: [],
            eventType: 'match',
            selectedMatches: [],
        },
        accreditationMatrix: {
            error: null,
            categories: [],
            subcategories: [],
            subcategoryTypes: [],
            accreditationTypes: [],
        }
    }),
    mutations: {
        selectAllAccreditationUsers(state, payload) {
            state.selectedUsers = payload.filter(user => !user.invalidFields.length)
        },
        selectAccreditationUser(state, user) {
            const selected = state.selectedUsers.find(({id}) => id === user.id)
            if (!selected) {
                state.selectedUsers = [...state.selectedUsers, user]
            } else {
                state.selectedUsers = state.selectedUsers.filter(({id}) => id !== selected.id)
            }
        },
        setAccreditationUsers(state, payload) {
            state.usersList = payload
        },
        changeActiveTab(state, payload) {
            state.activeTab = payload
        },
        clearState(state, payload) {
            state.activeTab = 'EventInformation'
            state.eventInfo = {match: null, matches: [], season: null, matchDay: null, matchDays: [], eventType: 'match', selectedMatches: []}
            state.selectedUsers = []
        }
    },
    actions: {
        async getAccreditationsMatrix({state}, payload) {
            const season = payload.selectedSeason

            if (season) {
                const res = await new DtoApiAccreditationMatrix().$get(season.competitionId)

                if (res.$error) {
                    state.accreditationMatrix.error = "Competition doesn't have Accreditation Matrix"
                } else {
                    const subcategories = Object.values(res.subcategories)
                    state.accreditationMatrix.categories = res.categories.map(category => {
                        category.subcategoryTypes = subcategories.filter(type => category.subcategories.includes(type.subcategoryId))
                        return category
                    })
                    const accreditationTypes = Object.values(res.accreditationTypes)
                    state.accreditationMatrix.subcategories = res.subcategories.map(category => {
                        category.accreditationTypes = accreditationTypes.filter(type => category.accreditationTypes.includes(type.accreditationTypeId))
                        return category
                    })
                    state.accreditationMatrix.accreditationTypes = Object.values(res.accreditationTypes)
                    state.accreditationMatrix.error = null
                }
            }
        },
        postAccreditationRequests({commit, state, rootGetters}, accreditations) {
            const organizationId = rootGetters['user/organizationId']
            const matchId = state.eventInfo.match ? state.eventInfo.match.matchId : null
            const seasonId = state.eventInfo.season ? state.eventInfo.season.seasonId : null

            new DtoApiPostAccreditations().$post(organizationId, matchId, seasonId, accreditations)
        },
    },
})
