import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {ActivationsCollection} from "@/classes/collections/Activations.collection";
import {ActivationModel} from "@/classes/models/Activation.model";
import get from "lodash.get";

export class DtoApiGetTicketList extends ApiRequestor {
  data;

  meta;

  async $get(params) {
    return this.$http('GET', `ticketRequests`, {params})
  }

  $toTicketsListCollection(arr) {
    const collection = new ActivationsCollection();
    const data = arr || this.data
    collection.models = data.map(item => {
      const model = new ActivationModel();

      model.ticketRequestId = get(item, 'ticketRequestId', '--')
      model.requestDate = get(item, 'requestDate', '--')
      model.match = get(item, 'match', '--')
      model.matchTitle = get(item, 'match.name', '--')
      model.matchdayTitle = get(item, 'match.matchday.name', '--')
      model.status = get(item, 'status', '--')
      model.zones = get(item, 'zones', '--')
      model.organization = get(item, 'organization', '--')
      model.mainContact = get(item, 'mainContact', '--')
      model.mainContactOverwrite = get(item, 'mainContactOverwrite', null)
      model.type = get(item, 'type', '--')

      return model;
    });
    return collection;
  }
}
