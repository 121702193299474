import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {ActivationsCollection} from "@/classes/collections/Activations.collection";
import {ActivationModel} from "@/classes/models/Activation.model";
import get from "lodash.get";

export class DtoApiGetActivationList extends ApiRequestor {
  data;

  meta;

  async $get(params) {
    return this.$http('GET', `bookedActivity`, {params})
  }

  $toActivationsListCollection(arr) {
    const collection = new ActivationsCollection();
    const data = arr || this.data
    collection.models = data.map(item => {
      const model = new ActivationModel();

      model.activityName = get(item, 'activity.name', '--')
      model.bookingId = get(item, 'bookingId', '--')
      model.contactFirstName = get(item, 'contactFirstName', '--')
      model.contactLastName = get(item, 'contactLastName', '--')
      model.matchTitle = get(item, 'match.name', '--')
      model.matchdayTitle = get(item, 'match.matchday.name', '--')
      model.requestDate = get(item, 'requestDate', '--')
      model.match = get(item, 'match', null)
      model.organization = get(item, 'organization', null)
      model.requestDate = get(item, 'requestDate', null)
      model.competition = get(item, 'competition', null)
      model.season = get(item, 'season', null)
      model.subcategories = get(item, 'subcategories', null)
      model.status = get(item, 'status', '--')

      model.personInfo = {
        contactFirstName: get(item, 'contactFirstName', ''),
        contactLastName: get(item, 'contactLastName', ''),
        contactEmail: get(item, 'contactEmail', ''),
        contactPhone: get(item, 'contactPhone', ''),
      }

      return model;
    });
    return collection;
  }
}
