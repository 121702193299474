const context = require.context('./plugins', true, /(.plugin.js)$/);
context.keys().forEach(plugin => context(plugin));

import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

import VueConfirmDialog from 'vue-confirm-dialog';
Vue.use(VueConfirmDialog);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
