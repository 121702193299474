import { ModelAbstraction } from "@/classes/abstractions/model.abstraction";

export class ContactModel extends ModelAbstraction {
  contactId;

  seasonId;

  season;

  firstname;

  lastname;

  address;

  city;

  zip;

  country;

  email;

  phone;

  team;

  teams;

  useForDelivery;
}
