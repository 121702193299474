import {CollectionAbstraction} from "@/classes/abstractions/collection.abstraction";
import {IsInstance, ValidateNested} from "class-validator";
import {AccreditationApplicant} from "@/classes/models/AccreditationApplicant.model";

export class ApplicantsCollection extends CollectionAbstraction {
    @IsInstance(AccreditationApplicant)
    @ValidateNested()
    models;

    constructor(models = []) {
        super(models);
        this.models = [...models];
    }

    async getFake(length = 10) {
        const model = new AccreditationApplicant();
        return await super.getFake(model, length);
    }
}
