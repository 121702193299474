import {CollectionAbstraction} from "@/classes/abstractions/collection.abstraction";
import {IsInstance, ValidateNested} from "class-validator";
import {TeamModel} from "@/classes/models/Team.model";

export class TeamsCollection extends CollectionAbstraction {
  @IsInstance(TeamModel)
  @ValidateNested()
  models;

  constructor(models = []) {
    super(models);
    this.models = [...models];
  }

  async getFake(length = 10) {
    const model = new TeamModel();
    return await super.getFake(model, length);
  }
}
