import 'reflect-metadata';
import get from 'lodash.get';
import isFunction from 'lodash.isfunction';

const metadataKeys = {
  method: 'METADATA_KEY_FAKER_METHOD',
  args: 'METADATA_KEY_FAKER_ARGS',
  nested: 'METADATA_KEY_FAKER_NESTED'
}

export const Fake = (method, ...args) => (target, key) => {
  Reflect.defineMetadata(metadataKeys.method, method, target, key);
  Reflect.defineMetadata(metadataKeys.args, args, target, key);
};

export const FakeNested = (constructor) => (target, key) => {
  Reflect.defineMetadata(metadataKeys.nested, constructor, target, key);
}

export const getFake = async target => {
  const instance = new target.constructor();
  const faker = await import('faker');
  const promises = Object.keys(instance).map(async key => {
    const method = Reflect.getMetadata(metadataKeys.method, instance, key);
    const caller = get(faker, method);
    const args = Reflect.getMetadata(metadataKeys.args, instance, key);
    const nested = Reflect.getMetadata(metadataKeys.nested, instance, key);

    if (isFunction(nested)) instance[key] = await new nested().getFake();
    else if (isFunction(caller)) instance[key] = caller(...args);
  });
  await Promise.all(promises);
  return instance;
}
