import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {IsInt, IsString} from "class-validator";

export class DtoApiPostAccreditations extends ApiRequestor {
  @IsInt()
  matchdayId

  @IsString()
  name

  @IsString()
  optionLabel

  @IsString()
  stageName

  @IsString()
  title

  async $post(organizationId, matchId = null, seasonId = null, accreditations) {
    const payload = { organizationId, matchId, seasonId, accreditations }
    return this.$http('POST', '/accreditationRequests', {payload})
  }
}
