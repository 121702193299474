<template>
  <section class="Authenticator" v-if="!this.$store.state.api.isAuthenticated">
    <div class="Authenticator__iframe">
      <div class="Authenticator__iframe-background"></div>
      <LoginWindow class="Authenticator__iframe-window" v-if="!this.$store.state.api.isLogout"/>
      <LogoutWindow v-else/>
    </div>
  </section>
</template>

<script>
import LoginWindow from './iframes/Login.iframe.component';
import LogoutWindow from './iframes/Logout.iframe.component';

export default {
  name: 'DDMCAF-Authenticator',
  components: {LoginWindow, LogoutWindow},
  async created() {
    await this.$store.dispatch('user/loadAuthInfo');
  }
}
</script>

<style lang="scss">
@import "src/styles/mixins/fill.mixin";

.Authenticator {
  &__iframe {
    &-background { background-color: #F4F6FC }
    &-window, &-background {
      @include fill();
      z-index: 1;
    }
  }
}
</style>
