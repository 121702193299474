import RouterNames from "@/router/route.names";

export default ({
  path: 'my-activations',
  name: RouterNames.IndexRoutes.DashboardRoutes.SponsorActivation,
  component: () => import('@/views/Dashboard/SponsorActivation/SponsorActivation.view'),
  children: [
    {
      path: '',
      redirect: { name: RouterNames.IndexRoutes.DashboardRoutes.SponsorActivationRoutes.SponsorActivationList }
    },
    {
      path: 'list',
      name: RouterNames.IndexRoutes.DashboardRoutes.SponsorActivationRoutes.SponsorActivationList,
      component: () => import('@/views/Dashboard/SponsorActivation/SponsorActivationRequests/ActivationsList.view'),
      props: { breadcrumbs: {text: 'Activation Requests', active: true} },
    },
    {
      path: ':request',
      name: RouterNames.IndexRoutes.DashboardRoutes.SponsorActivationRoutes.SponsorActivationDetails,
      component: () => import('@/views/Dashboard/SponsorActivation/SponsorActivationRequests/ActivationDetails.view'),
      props: { breadcrumbs: { text: 'Request Details', active: true } }
    }
  ]
})
