import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {IsNumber, IsString} from "class-validator";

export class TeamModel extends ModelAbstraction {
    @IsNumber()
    teamId;

    @IsString()
    name;

    @IsString()
    abbreviation;
}
