import {ApiRequestor} from "@/classes/requestors/api.requestor";

export class DtoApiGetTicketDetails extends ApiRequestor {
  data;

  status;

  async $get(organizationId, ticketRequestId) {
    const params = { organizationId }
    return this.$http('GET', `/ticketRequests/${ticketRequestId}`, {params})
  }
}